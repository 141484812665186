@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-main: #f1cc34 !important;
  --color-secondary: #222222;
  --color-third: #8d8b8b;
  --color-red: #c92c2c;
  --color-light: #fff;
  --color-dark: #000;
}

@font-face {
  font-family: "Cairo";
  src: local("Cairo Regular"),
    url("../assets/fonts/Cairo-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Cairo";
  src: local("Cairo Bold"),
    url("../assets/fonts/Cairo-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
}

body {
  font-family: "Cairo", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background-color: var(--color-light);
  cursor: default;
  margin: 0;
  padding: 0;
  height: 100vh;
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--color-secondary) var(--primary);
}

input {
  outline: none;
  padding-left: 14px;
  padding-right: 14px;
  display: block;
  width: 100%;
}
textarea {
  resize: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

*::-webkit-scrollbar {
  width: 15px;
}
*::-webkit-scrollbar-track {
  background: var(--primary);
}
*::-webkit-scrollbar-thumb {
  border-radius: 14px;
  border: 3px solid var(--primary);
}

ul li a.active,
ul li a:hover {
  font-weight: bold;
}
.sidebar li a.active,
.sidebar li a:hover {
  font-weight: bold;
  color: var(--color-light);
  background-color: var(--color-main) !important;
}
.scroll {
  scrollbar-width: none !important;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.bg-seller {
  background-image: url("../assets/images/BgSeller.png");
  background-position: center;
  background-repeat: no-repeat;
}
