.swiper-pagination-bullet {
  width: 10px !important ;
  height: 10px !important;
  background-color: var(--color-main) !important ;
  opacity: 0.5 !important;
}
.swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 35px !important;
  height: 10px !important;
  border-radius: 4px;
  background-color: var(--color-main);
  opacity: 1 !important;
}
